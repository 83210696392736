<template>
  <div>
    <default-app-bar :showCancel="false">
      <template v-slot:menu>
        <v-app-bar-nav-icon
          @click.stop="toogleDrawer"
          color="primary"
        ></v-app-bar-nav-icon>
      </template>
      <template v-slot:title>
        <v-toolbar-title class="px-0">
          <img
            src="/assets/images/brand/primary-icon-logo.svg"
            class="d-sm-block d-lg-none"
            width="40"
          />
          <img
            src="/assets/images/brand/primary-horizontal-logo.svg"
            class="d-none d-lg-block"
            width="180"
          />
        </v-toolbar-title>
      </template>

      <template v-slot:actions>
        <div v-if="!$store.getters.mobile">
          <v-btn icon @click="openHelp" title="Ayuda" class="blue lighten-5"
            ><v-icon color="primary">mdi-help-circle</v-icon>
          </v-btn>
        </div>
        <v-menu
          bottom
          left
          offset-y
          origin="top right"
          transition="scale-transition"
          class="account-menu"
        >
          <template v-slot:activator="{ on }">
            <v-btn dark icon v-on="on" class="ml-1" id="btn-menu-profile">
              <v-avatar size="45" class="primary">
                <v-icon color="white">mdi-account</v-icon>
              </v-avatar>
            </v-btn>
          </template>

          <v-list style="width: 350px !important">
            <v-list-item
              v-if="$store.getters.user"
              class="d-flex justify-center align-center"
            >
              <div class="account-header text-center">
                <v-list-item two-line class="pa-0 red mx-auto">
                  <div>
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-avatar
                          v-if="
                            $store.getters.company &&
                            $store.getters.company.logo
                          "
                        >
                          <img
                            :src="
                              $http.getBaseUrlPublicFiles() +
                              $store.getters.company.logo
                            "
                            :alt="$store.getters.company.name"
                          />
                        </v-list-item-avatar>

                        <v-list-item-content class="text-left">
                          <v-list-item-title
                            v-if="$store.getters.company"
                            class="pb-0 primary--text"
                            >{{ $store.getters.company.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="$store.getters.user">{{
                            $store.getters.user.name
                          }}</v-list-item-subtitle>

                          <div class="d-flex align-center">
                            <span
                              v-if="
                                $store.getters.user.rol &&
                                $store.getters.user.rol.name
                              "
                              class="me-1"
                              ><v-chip small>{{
                                $store.getters.user.rol.name
                              }}</v-chip></span
                            >
                            <span v-if="app_version" class="caption grey--text"
                              >v{{ app_version }}</span
                            >
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-list-item>
              </div>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item
              id="menu-item-logout"
              class="text-center grey--text mt-2"
            >
              <v-btn
                @click="$store.dispatch('logoutAuth')"
                elevation="0"
                title="Cerrar sesión"
                block
                outlined
                color="primary"
                class="rounded-lg"
                >{{ $t("logout") }}</v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <!--a v-if="$store.getters.mobile" @click="dialogFilter = true"><v-icon>mdi-magnify</v-icon></a-->
      </template>
    </default-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppMenu",
  data: () => ({
    app_version: process.env.VUE_APP_BASECONFIG_APP_VERSION,
    title: "AppMenu",
  }),
  mounted() {
    this.load();
  },
  methods: {
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    launchMenuPos(evt) {
      return false;
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    async load() {
      setTimeout(async () => {
        this.app_version = process.env.VUE_APP_BASECONFIG_APP_VERSION;
        await this.$db.load();
        const installed = await this.$db.itsInstalled();
      }, 50);
    },
  },
};
</script>
