<template>
  <div>
    <AppMenu />

    <!-- m="4" md="4" lg="4" -->
    <div tabindex="0" style="height: 80vh; overflow-y: auto">
      <v-row no-gutters class="py-lg-6 py-0 px-3 d-flex justify-center">
        <!-- sm="8" md="6" lg="6" offset-sm="2" offset-md="3" offset-lg="3"-->
        <v-col cols="12" md="8" style="max-width: 680px;">
          <ValidationObserver v-slot="{ invalid }" ref="form">
            <form class="v-form">
              <v-card>
                <template v-slot:default>
                  <div class="pa-3 d-flex align-center primary mb-5">
                    <h2 class="title white--text">{{ $t("close_turn") }}</h2>
                  </div>
                  <v-card-text
                    class="px-5 py-0 border-bottom"
                    v-if="totalOpenedOrders > 0"
                  >
                    <v-alert color="red" dark text class="mb-5">
                      <div class="d-flex align-center">
                        <v-progress-circular
                          indeterminate
                          class="me-2"
                        ></v-progress-circular>
                        <h3 class="font-weight-medium">
                          {{ $t("closed_turn_opened_orders") }}
                        </h3>
                      </div>
                    </v-alert>
                  </v-card-text>
                  <v-card-text
                    v-for="item in payments"
                    class="px-5 pb-0 border-bottom"
                    :key="item.id"
                  >
                    <v-row>
                      <v-col cols="6" md="3">
                        <h2 class="subtitle2 font-weight-bold darken-4--text">
                          {{ item.name }}
                        </h2></v-col
                      >
                      <v-col cols="6" md="3">
                        <h2 class="subtitle font-weight-regular black--text">
                          {{ $filters.currency(item.system_value, 0) }}
                        </h2>
                        <span class="font-weight-regular">
                          {{ $t("system_value") }}
                        </span>
                      </v-col>

                      <v-col cols="12" md="6">
                        <ng-number
                          class="mt-4 mb-0 fix_input_margin_top"
                          v-model="item.counted_value"
                          :label="$t('counted_value')"
                          rules="required"
                          show-required="true"
                        ></ng-number>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text
                    class="px-0 py-0 border-bottom"
                    v-if="$store.getters.turn"
                  >
                    <v-row no-gutters>
                      <v-col cols="12" lg="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon
                                class="green lighten-5 rounded-pill pa-1"
                                left
                                small
                                >mdi-currency-usd</v-icon
                              >
                              {{ $t("starting_amount") }}:
                              {{
                                $filters.currency(
                                  $store.getters.turn.starting_amount,
                                  0
                                )
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon
                                class="green lighten-5 rounded-pill pa-1"
                                left
                                small
                                >mdi-cash</v-icon
                              >
                              {{ $t("total_sales") }}:
                              {{
                                $filters.currency(totalInTurn(), 0)
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="12" lg="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon
                                class="green lighten-5 rounded-pill pa-1"
                                left
                                small
                                >mdi-cash-register</v-icon
                              >
                              {{ $t("total_cash_Register") }}:
                              {{
                                $filters.currency(totalWithTurn(), 0)
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="red--text">
                              <v-icon
                                class="red lighten-5 rounded-pill pa-1"
                                color="red"
                                left
                                small
                                >mdi-swap-horizontal</v-icon
                              >
                              {{ $t("Difference") }}:
                              {{
                                $filters.currency(calcDifference(), 0)
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="12" lg="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon
                                class="green lighten-5 rounded-pill pa-1"
                                left
                                small
                                >mdi-cash-register</v-icon
                              >
                              {{ $t("total_delivery") }}:
                              {{
                                $filters.currency(total_delivery, 0)
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col cols="12" lg="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-icon
                                class="green lighten-5 rounded-pill pa-1"
                                left
                                small
                                >mdi-cash</v-icon
                              >
                              {{ $t("total_tips") }}:
                              {{
                                $filters.currency(total_tips, 0)
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <!-- <v-row class="d-flex align-center">
                      <v-col cols="6" lg="6">
                        <div class="text d-flex align-center">
                          <v-icon class="green lighten-5 rounded-pill pa-2" left
                            >mdi-currency-usd</v-icon
                          >
                          <h1 class="text-h6 font-weight-regular">
                            {{ $t("starting_amount") }}
                          </h1>
                        </div>
                      </v-col>
                      <v-col cols="6" lg="6">
                        <h1 class="font-weight-regular">
                          {{
                            $filters.currency(
                              $store.getters.turn.starting_amount,
                              0
                            )
                          }}
                        </h1>
                      </v-col>
                    </v-row>

                    <v-divider class="my-5"></v-divider>

                    <v-row class="d-flex align-center">
                      <v-col cols="6" lg="6">
                        <div class="text d-flex align-center">
                          <v-icon class="green lighten-5 rounded-pill pa-2" left
                            >mdi-cash</v-icon
                          >
                          <h1 class="text-h6 font-weight-regular">
                            {{ $t("total_sales") }}:
                          </h1>
                        </div>
                      </v-col>
                      <v-col cols="6" lg="6">
                        <h1 class="font-weight-regular">
                          {{ $filters.currency(totalInTurn(), 0) }}
                        </h1>
                      </v-col>
                    </v-row>

              <v-divider class="my-5"></v-divider>

                    <v-row class="d-flex align-center">
                      <v-col cols="6" lg="6">
                        <div class="text d-flex align-center">
                          <v-icon class="green lighten-5 rounded-pill pa-2" left
                            >mdi-cash-register</v-icon
                          >
                          <h1 class="text-h6 font-weight-regular">
                            {{ $t("total_cash_Register") }}:
                          </h1>
                        </div>
                      </v-col>
                      <v-col cols="6" lg="6">
                        <h1 class="font-weight-regular">
                          {{ $filters.currency(totalWithTurn(), 0) }}
                        </h1>
                      </v-col>
                    </v-row>

               <v-divider class="my-5"></v-divider>

                    <v-row class="d-flex align-center">
                      <v-col cols="6" lg="6">
                        <div class="text d-flex align-center">
                          <v-icon class="red lighten-5 rounded-pill pa-2" left
                            >mdi-swap-horizontal</v-icon
                          >
                          <h1 class="text-h6 font-weight-regular red--text">
                            {{ $t("Difference") }}:
                          </h1>
                        </div>
                      </v-col>
                      <v-col cols="6" lg="6">
                        <h1 class="font-weight-regular red--text">
                          {{ $filters.currency(calcDifference(), 0) }}
                        </h1>
                      </v-col>
                    </v-row>

                    <v-divider class="my-5"></v-divider>

                    <v-row class="d-flex align-center">
                      <v-col cols="6" lg="6">
                        <div class="text d-flex align-center">
                          <v-icon class="green lighten-5 rounded-pill pa-2" left
                            >mdi-truck-delivery</v-icon
                          >
                          <h1 class="text-h6 font-weight-regular">
                            {{ $t("total_delivery") }}:
                          </h1>
                        </div>
                      </v-col>
                      <v-col cols="6" lg="6">
                        <h1 class="font-weight-regular">
                          {{ $filters.currency(total_delivery, 0) }}
                        </h1>
                      </v-col>
                    </v-row>

                    <v-divider class="my-5"></v-divider>

                    <v-row class="d-flex align-center">
                      <v-col cols="6" lg="6">
                        <div class="text d-flex align-center">
                          <v-icon class="green lighten-5 rounded-pill pa-2" left
                            >mdi-cash</v-icon
                          >
                          <h1 class="text-h6 font-weight-regular">
                            {{ $t("total_tips") }}:
                          </h1>
                        </div>
                      </v-col>
                      <v-col cols="6" lg="6">
                        <h1 class="font-weight-regular">
                          {{ $filters.currency(total_tips, 0) }}
                        </h1>
                      </v-col>
                    </v-row> -->
                    <v-divider class="my-5"></v-divider>
                  </v-card-text>
                  <v-card-text v-if="totalOpenedOrders == 0">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <ng-textarea
                          v-model="item.observation"
                          :label="$t('observation')"
                          :filled="false"
                          :dense="false"
                          rules="required"
                        ></ng-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions
                    class="mt-0 pt-0"
                    v-if="totalOpenedOrders == 0"
                  >
                    <v-row class="pa-1 pb-0 mb-0">
                      <v-col class="text-right">
                        <v-btn outlined color="primary" to="/pos">
                          {{ $t("cancel") }}
                        </v-btn>
                        <v-btn
                          class="ml-2"
                          color="primary"
                          @click="closeTurn()"
                          :disabled="invalid || openedOrders"
                          v-if="!print"
                        >
                          {{ $t("close_turn") }}
                        </v-btn>
                        <v-btn
                          class="ml-2"
                          color="primary"
                          @click="printTurn()"
                          :disabled="invalid"
                          v-else
                        >
                          {{ $t("print") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </template>
              </v-card>
            </form>
          </ValidationObserver>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
.fix_input_margin_top {
  margin-top: 0 !important;
}
</style>
<script>
import AppMenu from "../menu/AppMenu.vue";
export default {
  name: "CloseTurn",
  components: { AppMenu },
  data: () => ({
    key: 0,
    item: { amount: null, observation: "" },
    app_version: null,
    turn: null,
    payments: [],
    value_item: null,
    loading: false,
    openedOrders: false,
    print: false,
    total_delivery: 0,
    total_tips: 0,
    difference: 0,
    ordersNotSyncro: 0,
    turnToPrint: null,
    totalOpenedOrders: 0,
    //cart: null
    //priceList:
  }),

  watch: {
    "$store.getters.turn"(value) {
      console.log('value 1 ', value)
      if (value) {
        this.turn = value;
      }
    },
    turn(value) {
      console.log('value 2 ', value)
      if (value) {
        this.load();
      }
    },
  },
  mounted() {
    this.turn = this.$store.getters.turn;
  },
  methods: {
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    launchMenuPos(evt) {
      return false;
    },
    async load() {
      // let turn = this.$store.getters.turn;

      if (!this.turn) {
        this.$router.push("/");
        return;
      }
      this.calculateTotals(this.turn);
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    async calculateTotals(turn) {
      let infoTurn = await this.$pos.getTurnInfo(this.$store.getters.turn.id);
      this.payments = infoTurn.payments;
      this.total_delivery = infoTurn.totals.total_delivery;
      this.total_tips = infoTurn.totals.total_tips;
      this.totalOpenedOrders = infoTurn.totals.total_opened_orders;
    },

    totalInTurn() {
      let total = 0;
      for (const i in this.payments) {
        total += parseFloat(this.payments[i].system_value);
      }
      return total;
    },
    totalWithTurn() {
      let total = 0;
      for (const i in this.payments) {
        total += parseFloat(this.payments[i].system_value);
      }
      let turn = this.$store.getters.turn;
      if (turn) {
        if (turn.starting_amount) {
          total += parseFloat(turn.starting_amount);
        }
      }
      return total;
    },
    calcDifference() {
      let total_system = 0;
      let total_counted = 0;
      for (const i in this.payments) {
        total_system += parseFloat(this.payments[i].system_value);
        if (this.payments[i].counted_value) {
          total_counted += parseFloat(this.payments[i].counted_value);
        }
      }
      let turn = this.$store.getters.turn;
      if (turn) {
        if (turn.starting_amount) {
          total_system += parseFloat(turn.starting_amount);
        }
      }
      let difference = total_system - total_counted;
      this.difference = difference;
      return difference;
    },
    formatPayments() {
      let response = [];
      for (const key in this.payments) {
        if (this.payments.hasOwnProperty(key)) {
          this.payments[key].counted_value = parseFloat(
            this.payments[key].counted_value
          );
          response.push(this.payments[key]);
        }
      }
      return response;
    },
    getData() {
      //delete this.turn.opening_date;
      let turn = this.$store.getters.turn;
      turn.observation = this.item.observation;
      turn.data_payments = this.formatPayments();
      turn.final_amount = this.totalWithTurn();
      turn.difference = this.difference;
      turn.delivery_amount = this.total_delivery;
      turn.total_sales = this.totalInTurn();
      turn.tip_amount = this.total_tips;
      turn.opened = false;
      turn.closing_date = this.$filters.datetime(new Date());
      //this.turn.opening_date = this.$filters.datetime(this.turn.opening_date);
      if (turn.company && turn.company.id) {
        turn.company = turn.company.id;
      }
      if (turn.subsidiary && turn.subsidiary.id) {
        turn.subsidiary = turn.subsidiary.id;
      }

      return turn;
    },
    /*async closeLocalTurnAndSync() {
      this.updateTurn();
      try {
        let closed = await this.$pos.closeTurn(this.turn);
        return closed;
      } catch (e) {
        console.error(e);
        return false;
      }
    },*/
    async closeTurn() {
      let data = this.getData();
      this.turnToPrint = this.$store.getters.turn;
      let closed = await this.$pos.closeTurn(data);
      if (closed) {
        this.print = true;
        this.$store.commit("setTurn", null);
      }
    },
    printTurn() {
      return this.$pos.printTurn(this.turnToPrint);
    },
  },
};
</script>
