var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('default-app-bar',{attrs:{"showCancel":false},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('v-app-bar-nav-icon',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.toogleDrawer.apply(null, arguments)}}})]},proxy:true},{key:"title",fn:function(){return [_c('v-toolbar-title',{staticClass:"px-0"},[_c('img',{staticClass:"d-sm-block d-lg-none",attrs:{"src":"/assets/images/brand/primary-icon-logo.svg","width":"40"}}),_c('img',{staticClass:"d-none d-lg-block",attrs:{"src":"/assets/images/brand/primary-horizontal-logo.svg","width":"180"}})])]},proxy:true},{key:"actions",fn:function(){return [(!_vm.$store.getters.mobile)?_c('div',[_c('v-btn',{staticClass:"blue lighten-5",attrs:{"icon":"","title":"Ayuda"},on:{"click":_vm.openHelp}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-help-circle")])],1)],1):_vm._e(),_c('v-menu',{staticClass:"account-menu",attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"dark":"","icon":"","id":"btn-menu-profile"}},on),[_c('v-avatar',{staticClass:"primary",attrs:{"size":"45"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account")])],1)],1)]}}])},[_c('v-list',{staticStyle:{"width":"350px !important"}},[(_vm.$store.getters.user)?_c('v-list-item',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"account-header text-center"},[_c('v-list-item',{staticClass:"pa-0 red mx-auto",attrs:{"two-line":""}},[_c('div',[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[(
                          _vm.$store.getters.company &&
                          _vm.$store.getters.company.logo
                        )?_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.$http.getBaseUrlPublicFiles() +
                            _vm.$store.getters.company.logo,"alt":_vm.$store.getters.company.name}})]):_vm._e(),_c('v-list-item-content',{staticClass:"text-left"},[(_vm.$store.getters.company)?_c('v-list-item-title',{staticClass:"pb-0 primary--text"},[_vm._v(_vm._s(_vm.$store.getters.company.name)+" ")]):_vm._e(),(_vm.$store.getters.user)?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$store.getters.user.name))]):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[(
                              _vm.$store.getters.user.rol &&
                              _vm.$store.getters.user.rol.name
                            )?_c('span',{staticClass:"me-1"},[_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$store.getters.user.rol.name))])],1):_vm._e(),(_vm.app_version)?_c('span',{staticClass:"caption grey--text"},[_vm._v("v"+_vm._s(_vm.app_version))]):_vm._e()])],1)],1)],1)],1)])],1)]):_vm._e(),_c('v-divider'),_c('v-list-item',{staticClass:"text-center grey--text mt-2",attrs:{"id":"menu-item-logout"}},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"elevation":"0","title":"Cerrar sesión","block":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('logoutAuth')}}},[_vm._v(_vm._s(_vm.$t("logout")))])],1)],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }